html {
  overflow-x: hidden;
}

body {
  margin: 0;
}
.css-h4y409-MuiList-root {
  overflow-y: hidden;
}

.loginHeading {
  color: #002984;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.8rem;
  padding-bottom: 2rem;
  font-weight: 600;
}
@media (max-width: 859px) {
  .loginHeading {
    font-size: 12px;
  }
}

.dashboard-card {
  padding: 20px 30px 50px 30px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2),
    -2px -2px 2px 1px rgb(0 0 0 / 20%) !important;
}

.data-dashboard-card {
  padding: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2),
    -2px -2px 2px 1px rgb(0 0 0 / 20%) !important;
}

.dashboard-submit-button {
  float: right;
  padding: 10px 45px !important;
}

.admin-login-button {
  padding: 10px 45px !important;
  background: radial-gradient(
    110.62% 3146.67% at 104.38% 50%,
    #002984 0%,
    #3f51bf 100%
  ) !important;
}
@media (max-width: 856px) {
  .admin-login-button {
    width: 120px;
  }
}
.css-h4y409-MuiList-root {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  overflow-x: hidden !important;
}

.css-lkbdzw-MuiCardMedia-root {
  object-fit: contain !important;
}

.css-1ndgitk {
  padding-left: 70% !important;
}

.css-19z1ozs-MuiPaper-root-MuiAppBar-root {
  background-color: black !important;
}

.css-1191obr-MuiPaper-root-MuiAppBar-root {
  background-color: black !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus {
  overflow-x: auto !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  overflow-x: auto !important;
}
.css-1qaznj3-MuiCardContent-root {
  padding: 16px 16px 0px 16px !important;
}

@media (min-width: 1200px) {
  .dashboard-container {
    max-width: 1800px !important;
  }

  .dashboard-container {
    padding-left: 250px !important;
    padding-right: 24px;
  }
}

.dashboard-container-login {
  padding: 50px 30px !important;
  max-width: 500px !important;
}

/* Rabio Button */
.cc-selector input {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
/* .chat {
  background-image: url('./../assets/images/icons/chat-bubbles-with-ellipsis.png');
}
.call {
  background-image: url('./../assets/images/icons/phone-call.png');
}
.notes {
  background-image: url('./../assets/images/icons/sticky-note.png');
}
.face-to-face {
  background-image: url('./../assets/images/icons/talking.png');
}
.video {
  background-image: url('./../assets/images/icons/video-camera.png');
} */

.cc-selector input:active + .drinkcard-cc {
  opacity: 1;
}
.cc-selector input:checked + .drinkcard-cc {
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
}
.drinkcard-cc {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 15px 30px;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  -webkit-filter: brightness(1.8) grayscale(1) opacity(0.5);
  -moz-filter: brightness(1.8) grayscale(1) opacity(0.5);
  filter: brightness(1.8) grayscale(1) opacity(0.5);
}
.drinkcard-cc:hover {
  -webkit-filter: brightness(1.2) grayscale(0.5) opacity(0.8);
  -moz-filter: brightness(1.2) grayscale(0.5) opacity(0.8);
  filter: brightness(1.2) grayscale(0.5) opacity(0.8);
}

/* Extras */
a:visited {
  /* color: #888; */
}
a {
  /* color: #444; */
  text-decoration: none;
}
p {
  margin-bottom: 0.3em;
}

.css-79ws1d-MuiModal-root {
  overflow-y: scroll !important;
}

.page-404 {
  background-image: url('../../assets/img/blog1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 75vh;
  margin-right: 0px !important;
  margin-top: 1rem;
}

.page-401 {
  background-image: url('../../assets/img/blog1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 75vh;
  margin-right: 0px !important;
  margin-top: 1rem;
}
