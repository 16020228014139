html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #002984;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.SocialMediaIcons:hover svg path {
  fill: #45b6e3;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none !important;
  color: #002984;
}
a:hover {
  color: #3f51bf;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #fff inset; /* Change the color to your own background color */
  box-shadow: transparent;
  -webkit-text-fill-color: #fff;
}
body {
  background: #fff;
  color: #002984;
  font-family: 'Montserrat', 'Segoe UI', 'Khula', sans-serif;
  /* overflow-x: hidden; */
  font-size: 16px;
  font-weight: 400;
}
.container {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
}
.containerCard {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0px;
}
@media only screen and (max-width: 859px) {
  .container {
    padding: 0 15px;
  }
  .containerCard {
    padding: 0px 15px;
  }
  .pagination {
    padding: 20px 15px;
  }
}
.regular {
  font-weight: 400 !important;
}
.semiBold {
  font-weight: 600 !important;
}
.extraBold {
  font-weight: 800 !important;
}
.italic {
  font-style: italic;
}

/* HELPERS */
.textCenter {
  text-align: center;
}
.animate {
  transition: 0.3s ease;
}
.pointer {
  cursor: pointer;
}
.relative {
  position: relative;
}
.textCenter {
  text-align: center;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}
.x100 {
  width: 100%;
}

.active {
  padding: 10px;
}

.tag {
  padding: 5px 10px;
  margin: 10px 0;
}
.shadow {
  box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
}
/* FLEXBOX */
.flex {
  display: flex;
}
.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  /* max-height: 100vh; */
  align-items: center;
  /* width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  background-image: url('../assets/img/bg2.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  background-size: 1920px 1080px; */
}
.flexNullCenter {
  display: flex;
  align-items: center;
}
.flexColumn {
  flex-direction: column;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexSpaceNull {
  display: flex;
  justify-content: space-between;
}

/* FONT SIZES */
.font11 {
  font-size: 0.688rem;
}
.font12 {
  font-size: 0.75rem;
}
.font13 {
  font-size: 0.95rem;
  color: #000;
  font-family: 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.7rem;
  font-weight: 500;
}
.font14 {
  font-size: 0.95rem;
  color: #000;
  font-family: 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.font15 {
  font-size: 0.938rem;
}
.font18 {
  font-size: 1.125rem;
}
.font20Bold {
  color: #000;
  font-size: 1.15rem;
  font-weight: 600;
}
.font20 {
  color: #000;
  font-size: 1.25rem;
}
.font25 {
  font-size: 1.563rem;
}
.font30 {
  font-size: 1.875rem;
}
.font40 {
  font-size: 2.5rem;
  padding-bottom: 2rem;
}
.font40Number {
  font-size: 4.5rem;
}
.font60 {
  font-size: 3.75rem;
  line-height: 4.5rem;
}
@media only screen and (max-width: 859px) {
  .font11 {
    font-size: 0.688rem;
  }
  .font12 {
    font-size: 0.688rem;
  }
  .font13 {
    font-size: 0.75rem;
  }
  .font14 {
    font-size: 0.813rem;
  }
  .font15 {
    font-size: 0.813rem;
  }
  .font18 {
    font-size: 0.938rem;
  }
  .font20 {
    font-size: 1.125rem;
  }
  .font25 {
    font-size: 1.25rem;
  }
  .font30 {
    font-size: 1.5rem;
  }
  .font40 {
    font-size: 1.875rem;
    padding-bottom: 0.6rem;
  }
  .font60 {
    font-size: 2.8rem;
  }
}
/* BORDER RADIUS */
.radius8 {
  border-radius: 5px;
}
.radius6 {
  border-radius: 5px;
}
/* COLORS */
.lightBlack {
  color: #05111b;
}
.darkColor {
  color: #002984;
}
.purpleColor {
  color: #3f51bf;
}
.orangeColor {
  color: #f2b300;
}
.lightColor {
  color: #f2f4f8;
}
.coralColor {
  color: #c4ffff;
}
.greenColor {
  color: #49cb86;
}
.greyColor {
  color: #707070;
}
.whiteColor {
  color: #fff;
}
/* BACKGROUNDS */
.darkBg {
  background-color: #002984;
}
.purpleBg {
  background-color: #3f51bf;
}
.orangeBg {
  background-color: #f2b300;
}
.lightBg {
  background-color: #f2f4f8;
}

.coralBg {
  background-color: #c4ffff;
}
.greenBg {
  background-color: #49cb86;
}
.greyBg {
  background-color: #707070;
}
.whiteBg {
  background-color: #fefefe;
}
.NabBtn {
  background-color: #3f51bf;
}
.NabBtn:hover {
  background-color: #002984;
  border: 1px solid #3f51bf;
  color: #fff;
}
